import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChatRecommendationCreateRequest,
  ChatRecommendationMarkAsDeletedRequest,
  ChatRecommendationMarkAsViewedRequest,
  ChatRecommendationMarkMessageAsDeletedRequest,
  ChatRecommendationMarkMessageAsSentRequest,
  ChatRecommendationsGetResponse,
  DefaultResponse,
  ChatRecommendation,
} from '@api-clients/crm-api-client/dist';
import { ChatRecommendationDeleteReason } from '@api-clients/crm-api-client/dist/models/chat-recommendation-delete-reason';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatRecommendationsApiService {
  constructor(private http: HttpClient) {}

  create(chatId: string, dealId: number, text: string): Observable<ChatRecommendation> {
    const request: ChatRecommendationCreateRequest = {
      chatId,
      dealId,
      text,
    };
    return this.http.post<ChatRecommendation>(`${AppConfig.crmApiUrl}/chat/recommendations/create`, request);
  }

  get(dealIds: number[] | null = null): Observable<ChatRecommendationsGetResponse> {
    return this.http.get<ChatRecommendationsGetResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/get` + (dealIds ? `?dealIds=${dealIds.join(',')}` : ''),
    );
  }

  markAsViewed(recommendationId: number): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkAsViewedRequest = {
      recommendationId,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-as-viewed`,
      request,
    );
  }

  markAsDeleted(
    recommendationId: number,
    deleteReason: ChatRecommendationDeleteReason,
  ): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkAsDeletedRequest = {
      recommendationId,
      deleteReason,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-as-deleted`,
      request,
    );
  }

  markMessageAsSent(messageId: number, changedText: string = null): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkMessageAsSentRequest = {
      messageId,
      changedMessageText: changedText,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-message-as-sent`,
      request,
    );
  }

  markMessageAsDeleted(messageId: number): Observable<DefaultResponse> {
    const request: ChatRecommendationMarkMessageAsDeletedRequest = {
      messageId,
    };
    return this.http.post<DefaultResponse>(
      `${AppConfig.crmApiUrl}/chat/recommendations/mark-message-as-deleted`,
      request,
    );
  }
}
